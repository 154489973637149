// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import L from 'leaflet';
import MarkerClusterGroup from 'leaflet.markercluster'; // eslint-disable-line
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/slider';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui-touch-punch';

import noUiSlider from 'nouislider';

/* global ajax_object */
/* global FWP */

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();
/**
 * Init parallax
 */
jarallaxElement();
jarallax(document.querySelectorAll('.jarallax'), {
  speed: 0.1,
  imgHeight: 900,
});

/**
 * Fit slide video background to video holder
 */
// function resizeVideo() {
//   let $holder = $('.videoHolder');
//   $holder.each(function () {
//     let $that = $(this);
//     let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
//     let width = parseFloat(ratio.split(':')[0]);
//     let height = parseFloat(ratio.split(':')[1]);
//     $that.find('.video').each(function () {
//       if ($that.width() / width > $that.height() / height) {
//         $(this).css({
//           width: '100%',
//           height: 'auto',
//         });
//       } else {
//         $(this).css({
//           width: ($that.height() * width) / height,
//           height: '100%',
//         });
//       }
//     });
//   });
// }

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * remove key word in Breadcrumbs (Search page)
   */

  if (document.body.classList.contains('search-results')) {
    let breadcrumbLast = document.querySelector('.breadcrumb_last');
    let words = breadcrumbLast.innerText.split(' ');
    breadcrumbLast.innerText = words[0];
  }

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.start-button').on('click', function () {
    let scrollValue = $(window).width() <= 1200 ? 600 : 2000;
    $('html, body').animate(
      {
        scrollTop: scrollValue,
      },
      100
    );
  });

  let menuIcon = $('.menu-icon');
  let header = $('.header');
  let mainTitle = $('.header-main-title');

  menuIcon.on('click', function () {
    header.toggleClass('grey-bg');
    mainTitle.toggleClass('remove-title');
  });

  /**
   * Popup list Journals Date
   */

  $('.journals-post .popup-button').on('click', function () {
    let parentContainer = $(this).closest('.journals-post');
    $('.popup-list')
      .not(parentContainer.find('.popup-list'))
      .removeClass('popup-list-show');
    let popupList = parentContainer.find('.popup-list');
    popupList.toggleClass('popup-list-show');
  });

  $('.popup-list .close').on('click', function () {
    $(this).closest('.popup-list').removeClass('popup-list-show');
  });

  /**
   * Popup in text Single essays
   */

  function showPopup(popupId) {
    $('.essays-content-popup').hide();
    $('#' + popupId).show();
  }

  $('a[data-popup-target]')
    .mouseover(function () {
      let popupId = $(this).data('popup-target');
      showPopup(popupId);
    })
    .mouseout(function () {
      $('.essays-content-popup').hide();
    });

  /**
   * Open/Close Filters on mobile (Search & Gallery pages)
   */

  let filtersShowButton = $('.filters-bar__head');
  let filtersBlock = $('.search-filters__block-bar');

  filtersShowButton.on('click', function () {
    filtersBlock.toggleClass('show-filters');
    filtersShowButton.toggleClass('close-filters');
  });

  let filtersShowButtonGal = $('.filters-bar__head-gallery');
  let filtersBlockGal = $('.gallery-posts__tax-mob');
  let filtersWrapGal = $('.filters-bar__gallery');

  filtersShowButtonGal.on('click', function () {
    filtersBlockGal.toggleClass('show-filters');
    filtersShowButtonGal.toggleClass('close-filters');
    filtersWrapGal.toggleClass('crema-bg');
  });

  /**
   * Open/Close Accordion Point date
   */
  // $('.accordion-title__inner').each(function () {
  //   $(this).click(function () {
  //     $(this).toggleClass('hide-list');
  //     $(this).next('.accordion-content__inner').slideToggle();
  //   });
  // });
  $('.accordion-content__inner').hide();
  $('.accordion-title__inner.match-slug')
    .next('.accordion-content__inner')
    .show();
  $('.accordion-title__inner').each(function () {
    $(this).click(function () {
      $(this).toggleClass('hide-list');
      $(this).next('.accordion-content__inner').slideToggle();
    });
  });

  $('.tag').on('click', function (e) {
    e.preventDefault();
    let tagSlug = $(this).data('tag-slug');
    let searchURL =
      '/?s=&selected_post_types%5B%5D=watercolor_gallery&_subject=' + tagSlug;

    window.location.href = searchURL;
  });

  /**
   * Post Navigation additional class if nav have one element
   */
  let postNavigation = $('.journals-single .post-navigation');

  if (postNavigation.length > 0) {
    let postNavigationItems = postNavigation.find('a');

    if (postNavigationItems.length === 1) {
      postNavigation.addClass('single-nav');
    } else {
      postNavigation.removeClass('single-nav');
    }
  }

  /**
   * Open/Close Search Block
   */
  let searchButton = $('.nav-item-search');
  let searchBlock = $('.search-block');
  let searchClose = $('.close-search');

  searchButton.on('click', function () {
    searchBlock.addClass('search-block-display');
  });
  searchClose.on('click', function () {
    searchBlock.removeClass('search-block-display');
  });

  /**
   * Show and hide filters
   */

  if ($('.search-results').length > 0) {
    $(document).on('click', '.facet-wrap .facet-label', function () {
      $(this).toggleClass('hide-list');
      $(this).next('.facetwp-type-checkboxes').slideToggle();
    });
    $(document).on('click', '.facetwp-sort-title', function () {
      $(this).toggleClass('hide-list');
      $(this).next('.facetwp-sort-wrap').slideToggle();
    });
  }

  let sortfacet = 'sorting';
  $(document).on('click', '.sort-radio', function () {
    let val = $(this).attr('data-value');
    FWP.facets[sortfacet] = [val];
    FWP.toggleOverlay('on');
    FWP.fetchData();
    FWP.setHash();
  });

  $(document).on('facetwp-loaded', function () {
    if ('undefined' !== typeof FWP.facets[sortfacet]) {
      $('.sort-radio')
        .filter('[data-value="' + FWP.facets[sortfacet] + '"]')
        .addClass('checked');
    }
  });

  $(document).on('facetwp-loaded', function () {
    $.each(FWP.settings.num_choices, function (key, val) {
      let $facet = $('.facetwp-facet-' + key);
      let $wrap = $facet.closest('.facet-wrap');
      let $flyout = $facet.closest('.flyout-row');
      if ($wrap.length || $flyout.length) {
        let $which = $wrap.length ? $wrap : $flyout;
        0 === val ? $which.hide() : $which.show();
      }
    });
  });

  /**
   * Maximilian Map
   */

  if ($('#map').length) {
    let map = L.map('map').setView([41.25, -82.1], 3);

    let baseLayers = {
      'Mapbox Streets': L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
        {
          attribution: '© Mapbox attribution',
          id: 'sjappleford/cirmgky4c001fgim2cn2y8cjb',
          tileSize: 512,
          zoomOffset: -1,
          accessToken:
            'pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
        }
      ),
      OpenStreetMap: L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution: '© OpenStreetMap contributors',
        }
      ),
      // add other layers
    };

    let markerCluster = L.markerClusterGroup();
    let markerMap = $('.map-marker');
    let markersArray = [];

    markerMap.each(function () {
      let lat = $(this).data('lat');
      let long = $(this).data('lng');
      let popupContent = $(this).find('.popup-content').html();
      markersArray.push({
        coordinates: [lat, long],
        popupContent: popupContent,
      });
    });

    markersArray.forEach((markerData) => {
      let marker = L.marker(markerData.coordinates);
      marker.bindPopup(markerData.popupContent);
      markerCluster.addLayer(marker);
    });

    // Center mode
    markerCluster.on('clusterclick', function (event) {
      let clusterLatLng = event.latlng;
      map.setView(clusterLatLng, map.getZoom());
    });

    markerCluster.on('click', function (event) {
      let markerLatLng = event.layer.getLatLng();
      //let adjustedLatLng = L.latLng(markerLatLng.lat + 2.0, markerLatLng.lng);
      let adjustedLatLng = L.latLng(markerLatLng.lat, markerLatLng.lng);
      map.setView(adjustedLatLng, map.getZoom());
    });
    // End center mode

    baseLayers['Mapbox Streets'].addTo(map);
    markerCluster.addTo(map);

    let overlays = {
      Markers: markerCluster,
    };

    L.control.layers(baseLayers, overlays).addTo(map);
  }

  /**
   * Single page Maximilian Map
   */

  if ($('#sidebar-map').length) {
    let markerMapStart = $('.map-marker-sidebar');
    let firstMarkerLat = markerMapStart.first().data('lat');
    let firstMarkerLng = markerMapStart.first().data('lng');

    let map = L.map('sidebar-map').setView([firstMarkerLat, firstMarkerLng], 8);

    // let map = L.map('sidebar-map').setView([41.25, -42.1], 3);

    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
      {
        attribution: '© Mapbox attribution',
        id: 'sjappleford/cirmgky4c001fgim2cn2y8cjb',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
      }
    ).addTo(map);

    let markerMap = $('.map-marker-sidebar');

    markerMap.each(function () {
      let lat = $(this).data('lat');
      let long = $(this).data('lng');

      L.marker([lat, long]).addTo(map);
    });
  }

  /**
   * Taxonomy page Maximilian Map
   */

  if ($('#sidebar-map-term').length) {
    let markerMapStart = $('.map-marker-sidebar__term');
    let firstMarkerLat = markerMapStart.first().data('lat');
    let firstMarkerLng = markerMapStart.first().data('lng');

    let map = L.map('sidebar-map-term').setView(
      [firstMarkerLat, firstMarkerLng],
      8
    );

    // let map = L.map('sidebar-map').setView([41.25, -42.1], 3);

    L.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
      {
        attribution: '© Mapbox attribution',
        id: 'sjappleford/cirmgky4c001fgim2cn2y8cjb',
        tileSize: 512,
        zoomOffset: -1,
        accessToken:
          'pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
      }
    ).addTo(map);

    let markerMap = $('.map-marker-sidebar__term');

    markerMap.each(function () {
      let lat = $(this).data('lat');
      let long = $(this).data('lng');

      L.marker([lat, long]).addTo(map);
    });
  }

  /**
   * Bodmer Map
   */

  if ($('#map-bod').length) {
    let map = L.map('map-bod').setView([41.25, -82.1], 3);

    let baseLayers = {
      'Mapbox Streets': L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
        {
          attribution: '© Mapbox attribution',
          id: 'sjappleford/cirmgky4c001fgim2cn2y8cjb',
          tileSize: 512,
          zoomOffset: -1,
          accessToken:
            'pk.eyJ1Ijoic2phcHBsZWZvcmQiLCJhIjoiaVhBQWZKQSJ9.HrEDZTOIcoIJcoIpEuvxbQ',
        }
      ),
      OpenStreetMap: L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          attribution: '© OpenStreetMap contributors',
        }
      ),
      // add other layers
    };

    let markerCluster = L.markerClusterGroup();
    let markerMap = $('.map-marker');
    let markersArray = [];

    markerMap.each(function () {
      let lat = $(this).data('lat');
      let long = $(this).data('lng');
      let popupContent = $(this).find('.popup-content').html();
      markersArray.push({
        coordinates: [lat, long],
        popupContent: popupContent,
      });
    });

    markersArray.forEach((markerData) => {
      let marker = L.marker(markerData.coordinates);
      marker.bindPopup(markerData.popupContent);
      markerCluster.addLayer(marker);
    });

    baseLayers['Mapbox Streets'].addTo(map);
    markerCluster.addTo(map);

    // Center mode
    markerCluster.on('clusterclick', function (event) {
      let clusterLatLng = event.latlng;
      map.setView(clusterLatLng, map.getZoom());
    });

    markerCluster.on('click', function (event) {
      let markerLatLng = event.layer.getLatLng();
      //let adjustedLatLng = L.latLng(markerLatLng.lat + 4.0, markerLatLng.lng);
      let adjustedLatLng = L.latLng(markerLatLng.lat, markerLatLng.lng);
      map.setView(adjustedLatLng, map.getZoom());
    });
    // End center mode

    let overlays = {
      Markers: markerCluster,
    };

    L.control.layers(baseLayers, overlays).addTo(map);
  }

  /**
   * AJAX CATEGORY FILTER
   */

  let perpage = 5;
  let catBtn = $('.category-link');
  catBtn.on('click', function () {
    let category = $(this).attr('data-category');
    catBtn.removeClass('active');
    $(this).addClass('active');

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url, // get from wp_localize_script()
      data: {
        action: 'tax_lesson_filter', // action for wp_ajax_ & wp_ajax_nopriv_
        category: category, //chosen category
        ppp: perpage,
      },
      beforeSend: function () {},
      success: function (data) {
        $('.posts-wrap').html(data.data);
        $('.ajax-posts').attr('data-category', category);
      },
    });
  });

  $(document).on('click', 'ul.my-pagination li a', function (e) {
    e.preventDefault();
    let max_page = $('.ajax-posts').data('max-pages'),
      category = $('.ajax-posts').data('category'),
      current_page = $(this).data('page'),
      data = {
        action: 'lesson_pagination',
        page: current_page,
        max_page: max_page,
        category: category,
      };
    console.log(data);
    $.ajax({
      // you can also use $.post here
      url: ajax_object.ajax_url, // AJAX handler
      data: data,
      type: 'POST',
      beforeSend: function () {
        //button.text('Loading...'); // change the button text, you can also add a preloader image
      },
      success: function (data) {
        if (data) {
          $('.ajax-posts').html(data.loop); // insert new posts
          $('.my-pagination').html(data.pagin);
        } else {
          // button.remove(); // if no data, remove the button as well
        }
      },
    });
  });

  /**
   * AJAX CATEGORY FILTER GALLERY
   */

  // let perPageGallery = 9;
  // let catBtnGallery = $('.category-link__gallery');
  // let artists = [];
  // let medium = [];
  // let institution = [];
  // let tag = [];
  // let date = [];
  // //let sortDropdown = $('.gallery-select');
  //
  // // format date for range
  // let minDateSlug = $('#slider-range').attr('data-min-date');
  // let maxDateSlug = $('#slider-range').attr('data-max-date');
  //
  // if (minDateSlug) {
  //   let minDateSlugFormatted = formatDateToSlug(minDateSlug);
  //   console.log(minDateSlugFormatted);
  // }
  //
  // if (maxDateSlug) {
  //   let maxDateSlugFormatted = formatDateToSlug(maxDateSlug);
  //   console.log(maxDateSlugFormatted);
  // }
  //
  // function formatDateToSlug(dateString) {
  //   if (!dateString) {
  //     return '';
  //   }
  //
  //   const months = {
  //     Jan: 'January',
  //     Feb: 'February',
  //     Mar: 'March',
  //     Apr: 'April',
  //     May: 'May',
  //     Jun: 'June',
  //     Jul: 'July',
  //     Aug: 'August',
  //     Sep: 'September',
  //     Oct: 'October',
  //     Nov: 'November',
  //     Dec: 'December',
  //   };
  //
  //   const [day, shortMonth, year] = dateString.split(' ');
  //
  //   if (!day || !shortMonth || !year) {
  //     console.error('Invalid date string values:', dateString);
  //     return '';
  //   }
  //
  //   const fullMonth = months[shortMonth];
  //   return `${fullMonth.toLowerCase()}-${day.toLowerCase()}-${year}`;
  // }
  //
  // // range data
  // $(function () {
  //   $('#slider-range').slider({
  //     range: true,
  //     min: Date.parse(minDateSlug),
  //     max: Date.parse(maxDateSlug),
  //     values: [Date.parse(minDateSlug), Date.parse(maxDateSlug)],
  //     slide: function (event, ui) {
  //       $('#amount').val(
  //         $.datepicker.formatDate('dd M yy', new Date(ui.values[0])) +
  //           ' - ' +
  //           $.datepicker.formatDate('dd M yy', new Date(ui.values[1]))
  //       );
  //       date = [];
  //       let startDate = new Date(ui.values[0]);
  //       let endDate = new Date(ui.values[1]);
  //       let currentDate = startDate;
  //
  //       while (currentDate <= endDate) {
  //         date.push(
  //           $.datepicker.formatDate('MM-dd-yy', currentDate).toLowerCase()
  //         );
  //         currentDate.setDate(currentDate.getDate() + 1);
  //       }
  //
  //       handleFilter.call(this);
  //     },
  //   });
  //
  //   // jQuery(document).on(
  //   //   'touchstart',
  //   //   '#slider-range .ui-slider-handle',
  //   //   function (e) {
  //   //     let t = e.touches[0] || e;
  //   //     jQuery(this)
  //   //       .addClass('ui-state-hover')
  //   //       .addClass('ui-state-active')
  //   //       .addClass('ui-state-focus');
  //   //     var newEvent = new MouseEvent('mousedown', {
  //   //       screenX: t.screenX,
  //   //       screenY: t.screenY,
  //   //       clientX: t.clientX,
  //   //       clientY: t.clientY,
  //   //       relatedTarget: t.target,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'target', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'currentTarget', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     //jQuery(this).parent().slider()._mouseDown(newEvent);
  //   //     sliderUi.slider('_mouseMove', newEvent);
  //   //   }
  //   // );
  //   // jQuery(document).on(
  //   //   'touchend',
  //   //   '#slider-range .ui-slider-handle',
  //   //   function (e) {
  //   //     let t = e.touches[0] || e;
  //   //     jQuery(this)
  //   //       .removeClass('ui-state-hover')
  //   //       .removeClass('ui-state-active')
  //   //       .removeClass('ui-state-focus');
  //   //     var newEvent = new MouseEvent('mouseup', {
  //   //       screenX: t.screenX,
  //   //       screenY: t.screenY,
  //   //       clientX: t.clientX,
  //   //       clientY: t.clientY,
  //   //       relatedTarget: t.target,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'target', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'currentTarget', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     //jQuery(this).parent().slider()._mouseUp(newEvent);
  //   //     sliderUi.slider('_mouseUp', newEvent);
  //   //   }
  //   // );
  //   // jQuery(document).on(
  //   //   'touchmove',
  //   //   '#slider-range .ui-slider-handle',
  //   //   function (e) {
  //   //     let t = e.touches[0] || e;
  //   //     var newEvent = new MouseEvent('mousemove', {
  //   //       screenX: t.screenX,
  //   //       screenY: t.screenY,
  //   //       clientX: t.clientX,
  //   //       clientY: t.clientY,
  //   //       relatedTarget: t.target,
  //   //       bubbles: true,
  //   //       cancelable: true,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'target', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     Object.defineProperty(newEvent, 'currentTarget', {
  //   //       value: t.target,
  //   //       enumerable: true,
  //   //     });
  //   //     //jQuery(this).parent().slider()._mouseMove(newEvent);
  //   //     sliderUi.slider('_mouseMove', newEvent);
  //   //   }
  //   // );
  //
  //   $('#amount').val(
  //     $.datepicker.formatDate(
  //       'dd M yy',
  //       new Date($('#slider-range').slider('values', 0))
  //     ) +
  //       ' - ' +
  //       $.datepicker.formatDate(
  //         'dd M yy',
  //         new Date($('#slider-range').slider('values', 1))
  //       )
  //   );
  // });
  //
  // // post per page
  // $('.select-filter-ppp li').on('click', function () {
  //   let selectedValue = $(this).attr('value');
  //   if (!isNaN(selectedValue) && selectedValue > 0) {
  //     perPageGallery = parseInt(selectedValue);
  //     handleFilter();
  //   } else {
  //     $('.select-placeholder-ppp').text($(this).text());
  //     handleFilter(selectedValue);
  //   }
  // });
  //
  // // post sorting
  // $('.select-filter__item').on('click', function () {
  //   let selectedValue = $(this).attr('value');
  //   //sortDropdown.find('.select-placeholder').text($(this).text());
  //   handleFilter(selectedValue);
  // });
  //
  // $('.select-filter li span').on('click', function () {
  //   let checkbox = $(this).siblings('.posts__tax-link');
  //   checkbox.prop('checked', !checkbox.prop('checked')).change();
  //   handleFilter();
  // });
  //
  // function handleFilter(sortBy) {
  //   let categoryGallery = $(this).attr('data-category');
  //
  //   let countChecked = function () {
  //     let checkedArtists = $('.artists-filter input[type=checkbox]:checked');
  //     artists = checkedArtists
  //       .map(function () {
  //         return $(this).attr('data-category-artists');
  //       })
  //       .get();
  //     let checkedMedium = $('.medium-filter input[type=checkbox]:checked');
  //     medium = checkedMedium
  //       .map(function () {
  //         return $(this).attr('data-category-medium');
  //       })
  //       .get();
  //     let checkedInst = $('.institution-filter input[type=checkbox]:checked');
  //     institution = checkedInst
  //       .map(function () {
  //         return $(this).attr('data-category-institution');
  //       })
  //       .get();
  //     let checkedTag = $('.tag-filter input[type=checkbox]:checked');
  //     tag = checkedTag
  //       .map(function () {
  //         return $(this).attr('data-category-tag');
  //       })
  //       .get();
  //   };
  //
  //   $('.select-filter input[type=checkbox]').on('click', countChecked);
  //   countChecked();
  //
  //   let sortParams = {
  //     order: 'DESC',
  //     orderby: '',
  //   };
  //
  //   switch (sortBy) {
  //     case 'date-desc':
  //       sortParams.orderby = 'date';
  //       break;
  //     case 'date-asc':
  //       sortParams.orderby = 'date';
  //       sortParams.order = 'ASC';
  //       break;
  //     case 'title-asc':
  //       sortParams.orderby = 'title';
  //       sortParams.order = 'ASC';
  //       break;
  //     case 'title-desc':
  //       sortParams.orderby = 'title';
  //       break;
  //   }
  //
  //   $.ajax({
  //     type: 'POST',
  //     url: ajax_object.ajax_url,
  //     data: {
  //       action: 'tax_gallery_filter',
  //       artists: artists,
  //       medium: medium,
  //       institution: institution,
  //       tag: tag,
  //       date: date,
  //       ppp: perPageGallery,
  //       sortParams: sortParams,
  //     },
  //     beforeSend: function () {},
  //     success: function (data) {
  //       $('.posts-wrap__gallery').html(data.data);
  //       $('.ajax-posts__gallery').attr('data-category', categoryGallery);
  //     },
  //   });
  // }
  //
  // catBtnGallery.on('click', handleFilter);
  //
  // $(document).on('click', 'ul.my-gallery-pagination li a', function (e) {
  //   e.preventDefault();
  //   let max_page = $('.ajax-posts__gallery').data('max-pages'),
  //     current_page = $(this).data('page'),
  //     data = {
  //       action: 'gallery_pagination',
  //       page: current_page,
  //       max_page: max_page,
  //       artists: artists,
  //       medium: medium,
  //       institution: institution,
  //       tag: tag,
  //       date: date,
  //       category: catBtnGallery.data('category'),
  //       ppp: perPageGallery,
  //     };
  //
  //   $.ajax({
  //     url: ajax_object.ajax_url,
  //     data: data,
  //     type: 'POST',
  //     beforeSend: function () {},
  //     success: function (data) {
  //       if (data) {
  //         $('.ajax-posts__gallery').html(data.loop);
  //         $('.my-gallery-pagination').html(data.pagin);
  //       }
  //     },
  //   });
  // });

  let perPageGallery = 9;
  let catBtnGallery = $('.category-link__gallery');
  let artists = [];
  let medium = [];
  let institution = [];
  let tag = [];
  let date = [];
  let tribes = [];
  //let sortDropdown = $('.gallery-select');

  // format date for range
  // let minDateSlug = $('#slider-range').attr('data-min-date');
  // let maxDateSlug = $('#slider-range').attr('data-max-date');

  let minDateSlug = $('#slider-noui').attr('data-min-date');
  let maxDateSlug = $('#slider-noui').attr('data-max-date');

  if (minDateSlug) {
    let minDateSlugFormatted = formatDateToSlug(minDateSlug);
    console.log(minDateSlugFormatted);
  }

  if (maxDateSlug) {
    let maxDateSlugFormatted = formatDateToSlug(maxDateSlug);
    console.log(maxDateSlugFormatted);
  }

  function formatDateToSlug(dateString) {
    if (!dateString) {
      return '';
    }

    const months = {
      Jan: 'January',
      Feb: 'February',
      Mar: 'March',
      Apr: 'April',
      May: 'May',
      Jun: 'June',
      Jul: 'July',
      Aug: 'August',
      Sep: 'September',
      Oct: 'October',
      Nov: 'November',
      Dec: 'December',
    };

    const [day, shortMonth, year] = dateString.split(' ');

    if (!day || !shortMonth || !year) {
      console.error('Invalid date string values:', dateString);
      return '';
    }

    const fullMonth = months[shortMonth];
    return `${fullMonth.toLowerCase()}-${day.toLowerCase()}-${year}`;
  }

  let sliderNoUi = document.getElementById('slider-noui');
  // range data
  $(function () {
    let minDate = Date.parse(minDateSlug);
    let maxDate = Date.parse(maxDateSlug);
    noUiSlider.create(sliderNoUi, {
      range: {
        min: minDate,
        max: maxDate,
      },
      start: [minDate, maxDate],
      connect: true,
    });

    sliderNoUi.noUiSlider.on('change', function (values, handle) {
      date = [];
      let startDate = new Date(parseInt(values[0]));
      let endDate = new Date(parseInt(values[1]));
      let currentDate = startDate;

      while (currentDate <= endDate) {
        date.push(
          $.datepicker.formatDate('MM-dd-yy', currentDate).toLowerCase()
        );
        currentDate.setDate(currentDate.getDate() + 1);
      }

      console.log(handle);
      handleFilter();
    });

    sliderNoUi.noUiSlider.on('update', function (values, handle) {
      let startDate = new Date(parseInt(values[0]));
      let endDate = new Date(parseInt(values[1]));
      if (values) {
        $('#amount').val(
          $.datepicker.formatDate('dd M yy', new Date(startDate)) +
            ' - ' +
            $.datepicker.formatDate('dd M yy', new Date(endDate))
        );
      }
      console.log(handle);
    });
  });

  // post per page
  $('.select-filter-ppp li').on('click', function () {
    let selectedValue = $(this).attr('value');
    if (!isNaN(selectedValue) && selectedValue > 0) {
      perPageGallery = parseInt(selectedValue);
      handleFilter();
    } else {
      $('.select-placeholder-ppp').text($(this).text());
      handleFilter(selectedValue);
    }
  });

  // post sorting
  $('.select-filter__item').on('click', function () {
    let selectedValue = $(this).attr('value');
    //sortDropdown.find('.select-placeholder').text($(this).text());
    handleFilter(selectedValue);
  });

  $('.select-filter li span').on('click', function () {
    let checkbox = $(this).siblings('.posts__tax-link');
    checkbox.prop('checked', !checkbox.prop('checked')).change();
    handleFilter();
  });

  function handleFilter(sortBy) {
    let categoryGallery = $(this).attr('data-category');

    let countChecked = function () {
      let checkedArtists = $('.artists-filter input[type=checkbox]:checked');
      artists = checkedArtists
        .map(function () {
          return $(this).attr('data-category-artists');
        })
        .get();
      let checkedMedium = $('.medium-filter input[type=checkbox]:checked');
      medium = checkedMedium
        .map(function () {
          return $(this).attr('data-category-medium');
        })
        .get();
      let checkedInst = $('.institution-filter input[type=checkbox]:checked');
      institution = checkedInst
        .map(function () {
          return $(this).attr('data-category-institution');
        })
        .get();
      let checkedTag = $('.tag-filter input[type=checkbox]:checked');
      tag = checkedTag
        .map(function () {
          return $(this).attr('data-category-tag');
        })
        .get();
      let checkedTribes = $('.tribes-filter input[type=checkbox]:checked');
      tribes = checkedTribes
        .map(function () {
          return $(this).attr('data-category-tribes');
        })
        .get();
    };

    $('.select-filter input[type=checkbox]').on('click', countChecked);
    countChecked();

    let sortParams = {
      order: 'ASC',
      orderby: '',
    };

    switch (sortBy) {
      case 'date-desc':
        sortParams.orderby = 'date';
        sortParams.order = 'DESC';
        break;
      case 'date-asc':
        sortParams.orderby = 'date';
        sortParams.order = 'ASC';
        break;
      case 'title-asc':
        sortParams.orderby = 'title';
        sortParams.order = 'ASC';
        break;
      case 'title-desc':
        sortParams.orderby = 'title';
        break;
    }

    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      data: {
        action: 'tax_gallery_filter',
        artists: artists,
        medium: medium,
        institution: institution,
        tag: tag,
        date: date,
        tribes: tribes,
        ppp: perPageGallery,
        sortParams: sortParams,
      },
      beforeSend: function () {},
      success: function (data) {
        $('.posts-wrap__gallery').html(data.data);
        $('.ajax-posts__gallery').attr('data-category', categoryGallery);
      },
    });
  }

  catBtnGallery.on('click', handleFilter);

  $(document).on('click', 'ul.my-gallery-pagination li a', function (e) {
    e.preventDefault();
    let max_page = $('.ajax-posts__gallery').data('max-pages'),
      current_page = $(this).data('page'),
      data = {
        action: 'gallery_pagination',
        page: current_page,
        max_page: max_page,
        artists: artists,
        medium: medium,
        institution: institution,
        tag: tag,
        date: date,
        tribes: tribes,
        category: catBtnGallery.data('category'),
        ppp: perPageGallery,
      };

    $.ajax({
      url: ajax_object.ajax_url,
      data: data,
      type: 'POST',
      beforeSend: function () {},
      success: function (data) {
        if (data) {
          $('.ajax-posts__gallery').html(data.loop);
          $('.my-gallery-pagination').html(data.pagin);
        }
      },
    });
  });

  // var slider = document.getElementById('slider-noui');
  // var sliderValues = document.getElementById('slider-noui-values');
  //
  // noUiSlider.create(slider, {
  //   start: [20, 80],
  //   connect: true,
  //   range: {
  //     min: 0,
  //     max: 100,
  //   },
  // });
  //
  //
  // updateValues(slider.noUiSlider.get());
  //
  // slider.noUiSlider.on('slide', function (values, handle) {
  //   // Оновіть значення чи викличте власну функцію обробки торкання
  //   updateValues(values);
  //   console.log(handle);
  // });
  //
  // function updateValues(values) {
  //   // Оновіть значення в блоку або в іншому місці
  //   sliderValues.innerHTML =
  //     'Controller 1: ' + values[0] + '<br>Controller 2: ' + values[1];
  // }

  /**
   * Clear filters
   */

  let clearBtn = $('.gallery-posts__tax #clear-filters');
  let clearBtnMob = $('.sort-clear-mob #clear-filters');

  clearBtn.on('click', function (e) {
    e.preventDefault();
    resetSliderAndFilter();
  });

  clearBtnMob.on('click', function (e) {
    e.preventDefault();
    resetSliderAndFilter();
  });

  function resetSliderAndFilter() {
    $('.select-filter input[type=checkbox]').prop('checked', false);
    let sliderNoUi = document.getElementById('slider-noui');
    sliderNoUi.noUiSlider.reset();
    let initialStartDate = new Date(
      parseInt(sliderNoUi.noUiSlider.options.range.min)
    );
    let initialEndDate = new Date(
      parseInt(sliderNoUi.noUiSlider.options.range.max)
    );
    date = [];
    let currentDate = initialStartDate;

    while (currentDate <= initialEndDate) {
      date.push($.datepicker.formatDate('MM-dd-yy', currentDate).toLowerCase());
      currentDate.setDate(currentDate.getDate() + 1);
    }

    handleFilter();
  }

  /**
   * Custom filter select
   */

  // $('.posts__list-select .select-placeholder').on('click', function () {
  //   let $filter = $(this).siblings('.select-filter');
  //   if ($filter.is(':visible')) {
  //     $filter.hide();
  //     $(this).removeClass('select-active');
  //   } else {
  //     $('.posts__list-select .select-filter').not($filter).hide();
  //     $filter.toggle();
  //     $(this).toggleClass('select-active');
  //     updateOtherListsPosition();
  //   }
  // });
  //
  // function updateOtherListsPosition() {
  //   let selectFilterHeight = $(
  //     '.posts__list-select .select-filter:visible'
  //   ).height();
  //   $('.posts__list-select').css('margin-bottom', selectFilterHeight + 20);
  // }

  $('.posts__list-select .select-filter').hide();
  $('.posts__list-select .select-placeholder').on('click', function () {
    let $filter = $(this).siblings('.select-filter');
    let $list = $(this).closest('.posts__list-select');

    let $otherLists = $('.posts__list-select').not($list);

    if ($filter.is(':visible')) {
      $filter.hide();
      $(this).removeClass('select-active');
      $list.css('margin-bottom', 10);
    } else {
      $otherLists.removeClass('select-active');
      $otherLists.find('.select-filter').hide();
      $otherLists.css('margin-bottom', 10);

      $filter.toggle();
      $(this).toggleClass('select-active');

      if ($filter.is(':visible')) {
        let selectFilterHeight = $list.find('.select-filter:visible').height();
        $list.css(
          'margin-bottom',
          $(this).hasClass('select-active') ? selectFilterHeight + 20 : 10
        );
      } else if ($('.posts__list-select .select-filter:visible').length === 0) {
        $list.css('margin-bottom', 10);
      }
    }
  });

  $('.artists-select .select-placeholder').on('click', function () {
    $('.artists-select').toggleClass('select-active');
  });
  $('.medium-select .select-placeholder').on('click', function () {
    $('.medium-select').toggleClass('select-active');
  });
  $('.institution-select .select-placeholder').on('click', function () {
    $('.institution-select').toggleClass('select-active');
  });
  $('.tag-select .select-placeholder').on('click', function () {
    $('.tag-select').toggleClass('select-active');
  });

  /**
   * Sort select gallery & Post per page amount (ul li - select functionality)
   */

  $('.gallery-select .select-filter').hide();
  $('.gallery-select .select-placeholder').on('click', function () {
    $('.gallery-select').toggleClass('select-active');
    let $filter = $(this).siblings('.select-filter');
    if ($filter.is(':visible')) {
      $filter.hide();
    } else {
      $('.gallery-select .select-filter').not($filter).hide();
      $filter.toggle();
    }
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.gallery-select').length) {
      $('.gallery-select .select-filter').hide();
    }
  });
  $('.gallery-select .select-placeholder, .gallery-select .select-filter').on(
    'click',
    function (e) {
      e.stopPropagation();
    }
  );
  $('.select-filter .select-filter__item').on('click', function () {
    $('.select-filter .select-filter__item.item-active').removeClass(
      'item-active'
    );
    $(this).addClass('item-active');
  });

  $('.gallery-select-ppp .select-filter-ppp').hide();
  $('.gallery-select-ppp .select-placeholder-ppp').on('click', function () {
    let $filter = $(this).siblings('.select-filter-ppp');
    if ($filter.is(':visible')) {
      $filter.hide();
    } else {
      $('.gallery-select-ppp .select-filter-ppp').not($filter).hide();
      $filter.toggle();
    }
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.gallery-select-ppp').length) {
      $('.gallery-select-ppp .select-filter-ppp').hide();
    }
  });
  $(
    '.gallery-select-ppp .select-placeholder-ppp, .gallery-select-ppp .select-filter-ppp'
  ).on('click', function (e) {
    e.stopPropagation();
  });

  /**
   * Add wrapper for img in enty__content (max-width 640px)
   */

  $(document).ready(function () {
    checkWidth();
    $(window).resize(checkWidth);
  });

  function checkWidth() {
    let windowWidth = $(window).width();

    if (windowWidth < 640) {
      $('.journals-single__main .entry__content img').each(function () {
        if (!$(this).parent().hasClass('fw-img-wrap')) {
          $(this).wrap('<div class="fw-img-wrap"></div>');
        }
      });
    } else {
      $('.entry__content img.fw-img-wrap').each(function () {
        $(this).unwrap();
      });
    }
  }

  $('.all-terms-list').each(function () {
    if ($(this).text().trim() === '') {
      $(this).addClass('all-terms-list-empty');
    }
  });

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove alt in Leaflet marker
   */

  let leafletMarkerSelector = '.leaflet-marker-icon';
  $(leafletMarkerSelector).removeAttr('alt');

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  //resizeVideo();
});

$(document).ajaxComplete(function () {
  hideElements();
});
hideElements();

function hideElements() {
  var activeIndex = $('.my-pagination__item--pages.active').index();
  var totalElements = $('.my-pagination__item--pages').length;

  // Hide all elements
  $('.my-pagination__item--pages').addClass('hide-el');
  $('.ellipsis').remove();

  var visibleCount = window.innerWidth > 9999 ? 3 : 3;
  var startIndex = Math.max(0, activeIndex - Math.floor(visibleCount / 2));
  var endIndex = Math.min(totalElements - 1, startIndex + visibleCount - 1);

  for (var i = startIndex; i <= endIndex; i++) {
    $('.my-pagination__item--pages').eq(i).removeClass('hide-el');
  }

  $('.my-pagination__item--pages').first().removeClass('hide-el');
  $('.my-pagination__item--pages').last().removeClass('hide-el');

  if (startIndex > 1) {
    $('.my-pagination__item--pages')
      .eq(startIndex - 1)
      .after('<span class="ellipsis">...</span>');
  }

  if (endIndex < totalElements - 1) {
    $('.my-pagination__item--pages')
      .eq(endIndex + 1)
      .before('<span class="ellipsis">...</span>');
  }
}

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here
  //resizeVideo();
  hideElements();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  if ($(window).scrollTop() > 100) {
    $('.header').addClass('sticky-header');
    $('.grey-bg').addClass('decor-beige');
    $('.filters-bar').addClass('filters-bar-scroll');
  } else {
    //remove the background property so it comes transparent again (defined in your css)
    $('.header').removeClass('sticky-header');
    $('.grey-bg').removeClass('decor-beige');
    $('.filters-bar').removeClass('filters-bar-scroll');
  }

  if ($(window).scrollTop() > 420) {
    $('.filters-bar__gallery').addClass('filters-gallery-sticky');
  } else {
    $('.filters-bar__gallery').removeClass('filters-gallery-sticky');
  }
});

$(document).on('facetwp-loaded', function () {
  $('.facetwp-facet').each(function () {
    let facet = $(this);
    let facet_name = facet.attr('data-name');
    let facet_type = facet.attr('data-type');
    let facet_label = FWP.settings.labels[facet_name];
    if (facet_type !== 'pager' && facet_type !== 'sort') {
      if (
        facet.closest('.facet-wrap').length < 1 &&
        facet.closest('.facetwp-flyout').length < 1
      ) {
        facet.wrap('<div class="facet-wrap"></div>');
        facet.before('<h6 class="facet-label">' + facet_label + '</h6>');
      }
    }
  });
});

$(document).on('facetwp-loaded', function () {
  $('.facetwp-per-page-select').each(function () {
    let $this = $(this),
      numberOfOptions = $(this).children('option').length;
    $this.addClass('s-hidden');
    $this.wrap('<div class="select-pp"></div>');

    $this.after('<div class="custom-pp-select active"></div>');

    let $styledSelect = $this.next('div.custom-pp-select');

    $styledSelect.text($this.children('option').eq(0).text());

    let $list = $('<ul />', {
      class: 'options',
    }).insertAfter($styledSelect);

    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        value: $this.children('option').eq(i).val(),
      }).appendTo($list);
    }

    let $listItems = $list.children('li');

    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.styledSelect.active').each(function () {
        $(this).removeClass('active').next('ul.options').hide();
      });
      $(this).toggleClass('active').next('ul.options').toggle();
    });

    $listItems.click(function (e) {
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('value'));
      $list.hide();
      (FWP.extras.per_page = $(this).val()),
        (FWP.soft_refresh = !0),
        FWP.autoload();
    });

    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });
  });

  if ($('.facetwp-checkbox').is('.checked')) {
    $('.all-terms-list').hide();
  } else {
    $('.all-terms-list').show();
  }

  if ($('.facetwp-checkbox').is('.checked')) {
    $('.all-terms-list-after-filter').show();
  } else {
    $('.all-terms-list-after-filter').hide();
  }
});
